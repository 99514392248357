import {Button, Checkbox, Col, Input, notification, Row, Select, Space, Table, Tag, Tooltip, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {getListeNiveau} from "../../../../../../data/params/niveau";
import {getListeAssertionActive} from "../../../../../../data/params/assertion";
import {DeleteOutlined} from "@ant-design/icons";
import {getDetailsRisqueConception} from "../../../../../../data/risqueConceptionControle";
import TableTitle from "../../../../../common/TableTitle";
import {appTheme} from "../../../../../../config/theme";
import {Spinner} from "@chakra-ui/react";
import FormLabel from "../../../../../common/Form/FormLabel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartPlus} from "@fortawesome/free-solid-svg-icons";
import DynamicTable from "../../../../../modals/DynamicTable";

const {  Title } = Typography;

const postesStat = [
    { key: "EE3.1", title: "Immobilisations incorporelles" },
    { key: "EE3.2", title: "Immobilisations corporelles" },
    { key: "EE3.3", title: "Immobilisations financières" },
    { key: "EE3.4", title: "Stocks" },
    { key: "EE3.5", title: "Ventes-clients" },
    { key: "EE3.6", title: "Autres créance et dettes" },
    { key: "EE3.7", title: "Trésorerie" },
    { key: "EE3.8", title: "Capitaux propres" },
    { key: "EE3.9", title: "Provisions risques" },
    { key: "EE3.10", title: "Emprunts et assimilés" },
    { key: "EE3.11", title: "Achats fournisseurs" },
    { key: "EE3.12", title: "Impôts et taxes" },
    { key: "EE3.13", title: "Paie personnel" },
];

const TraitementControllesFonctionn = ({
       setSelectedData,
       selectedData,
       mission,
       chargement,
       risqueIncidences,
       // setProcedureData,
       // procedureData,
       setPrintData,
       isAddMemoire, 
       setIsAddMemoire
    }) => {
    const [loading, setLoading] = useState(false);
    const [index, setIndex] = useState(1);
    const [niveaux, setNiveaux] = useState([]);
    const [incidence, setIncidence] = useState({});
    const [assertions, setAssertions] = useState([]);

    useEffect(() => {
        const fetchAssertion = async () => {
            try {
                const res = await getListeAssertionActive();
                setAssertions(res.result);
            } catch (error) {
                console.error('Erreur lors de la récupération des rubriques', error);
            }
        };
        const fetchNiveaux = async () => {
            try {
                const res = await getListeNiveau();
                setNiveaux(res.result);
            } catch (error) {
                console.error('Erreur lors de la récupération des niveaux', error);
            }
        };

        fetchAssertion();
        fetchNiveaux();
    }, []);

    useEffect(() => {
        setLoading(true);
        if (risqueIncidences.length > 0) {
            if (Object.keys(incidence).length <= 0) {
                setIncidence(risqueIncidences[0]);
            }
        }
        setLoading(false);
    }, [mission, incidence, risqueIncidences]);

    useEffect(() => {
        getDetailsRisqueConception(mission.id)
            .then((res) => {
                if(res.result ){
                    const arrayData = []
                    res.result.procedures.forEach((opt) => {
                        const newElement = {
                            // id: opt.risqueDetails_id,
                            id: opt.id,
                            procedure: opt.libelleProcedure,
                            incidence_id: opt.risqueDetails_id,
                        }

                        arrayData.push(newElement)
                    })

                    const incidenceData = []
                    const descriptionsFetch = []

                    res.result.risques.forEach((item) => {
                        if(item.Lea_RisqueDescriptionControles){
                            item.Lea_RisqueDescriptionControles.map((risque) => {
                                const element = {
                                    question_id: index || 1,
                                    description: risque.description,
                                    resultat: risque.result,
                                    incidence_id: risque.risqueDetails_id,
                                    procedure_id: risque.procedure_id,
                                }

                                setIndex(prevState => prevState + 1);
                                descriptionsFetch.push(element)
                            })
                        }

                        if(item?.Lea_RisqueFonctionControles){
                            (item?.Lea_RisqueFonctionControles ?? [])?.map((risque) => {
                                const newElement = {
                                    assertions: risque.assertions,
                                    conclusion: risque.conclusion,
                                    recommandation: risque.recommandation,
                                    niveauResiduel_id: risque.niveauResiduel_id,
                                    incidence_id: risque.risqueDetails_id,
                                }

                                incidenceData.push(newElement)
                            })
                        }
                    })
                    
                    // setDescriptionData(descriptionsFetch)
                    if (selectedData.length <= 0) {
                        setSelectedData({
                            ...selectedData,
                            incidenceTab: incidenceData,
                            proceduresData: arrayData,
                            descriptionsData: descriptionsFetch,
                        });
                    }

                }
            })
    }, [ mission, selectedData, setSelectedData]);

    useEffect(() => {
        const updatedData = []

        risqueIncidences.map((risque) => {
            const incidenceSelect = (selectedData?.incidenceTab ?? [])?.find((opt) => opt.incidence_id === risque.id)

            const newElement = {
                id: risque.id,
                incidence: risque.incidence,
                postes: risque.postes,
                assertions: risque.assertions,
                niveau_id: risque.niveau_id,
                niveauLibelle: niveaux.find((item) => item.id === risque.niveau_id)?.libelle,
                // proceduresData: selectedData.proceduresData.filter((procedure) => procedure.incidence_id === risque.id).map((element) => ({
                //     questions: selectedData.descriptionsData.filter((question) => question.procedure_id === element.id).map((opt) => ({
                //         questionLibelle: opt.description,
                //         reponse: opt.resultat,
                //     })),
                //     procedure_id: element.id,
                //     procedure: element.procedure,
                // })),
                questions: (selectedData?.descriptionsData ?? [])?.filter((question) => question.incidence_id === risque.id).map((opt) => ({
                    questionLibelle: opt.description,
                    reponse: opt.resultat,
                })),
                procedures: (selectedData?.proceduresData ?? [])?.filter((procedure) => procedure.incidence_id === risque.id),
                niveauResiduel: niveaux.find((item) => item.id === incidenceSelect?.niveauResiduel_id)?.libelle,
                recommandation: incidenceSelect?.recommandation || "",
                conclusion: incidenceSelect?.conclusion || "",
            }

            updatedData.push(newElement)
        })

        setPrintData(updatedData)
    }, [risqueIncidences, niveaux, selectedData]);

    const handleAdd = () => {
        if(!incidence?.procedure){
            notification.error({
                description: "Veuillez sélectionner une procédure au préalable!",
            });
            return;
        }

        setLoading(true);
        setTimeout(() => {
            const newElement = {
                question_id: index,
                description: '',
                resultat: '',
                incidence_id: incidence.id,
                procedure_id: incidence?.procedure?.id
            };

            setIndex(prevState => prevState + 1);
            setSelectedData({
                ...selectedData,
                descriptionsData: [...selectedData.descriptionsData, newElement],
            });
            notification.success({
                description: "Description ajoutée avec succès !",
            });
            setLoading(false);
        }, 1000);
    };

    const handleDeleteElement = (id) => {
        setLoading(true);
        setTimeout(() => {
            const updatedData = [...selectedData.descriptionsData];
            const datas = updatedData.filter(data => data.question_id !== id);

            setSelectedData({
                ...selectedData,
                descriptionsData: datas
            })

            notification.success({
                description: "Description supprimée avec succès !",
            });
            setLoading(false)
        }, 1000);
    };

    const handleIncidenceNiveau = (value, incidence_id) => {
        const arrayList = selectedData.incidenceTab;

        let updateList = [];
        if(arrayList && arrayList.length > 0){
            const incidenceItem  = arrayList.find((item) => item.incidence_id === incidence_id)
            if(incidenceItem){
                updateList = arrayList.map((item) =>
                    item.incidence_id === incidence_id ? { ...item, niveauResiduel_id: parseInt(value) } : item
                );
            }
            else{
                const newElement = {
                    // id: incidence_id,
                    niveauResiduel_id: parseInt(value),
                    recommandation: '',
                    conclusion: '',
                    assertions: [],
                    incidence_id: incidence_id
                };

                updateList = [...arrayList, newElement]
            }

        }
        else {
            updateList = [{
                // id: incidence_id,
                niveauResiduel_id: parseInt(value),
                recommandation: '',
                conclusion: '',
                assertions: [],
                incidence_id: incidence_id
            }]
        }

        setSelectedData({
            ...selectedData,
            incidenceTab: updateList
        })
    }

    // const handleIncidenceAssertion = (e, incidence_id) => {
    //     const arrayList = selectedData.incidenceTab;
    //
    //     let updateList
    //     if(arrayList){
    //         const incidenceItem  = arrayList.find((item) => item.id === incidence_id)
    //         if(incidenceItem){
    //             updateList = arrayList.map((item) =>
    //                 item.id === incidence_id ? { ...item, assertions: e } : item
    //             );
    //         }
    //         else{
    //             const newElement = {
    //                 id: incidence_id,
    //                 niveauResiduel_id: '',
    //                 recommandation: '',
    //                 conclusion: '',
    //                 assertions: e,
    //                 incidence_id: incidence_id
    //             };
    //
    //             updateList = [...arrayList, newElement]
    //         }
    //
    //     }
    //     else {
    //         updateList = [{
    //             id: incidence_id,
    //             niveauResiduel_id: '',
    //             recommandation: '',
    //             conclusion: '',
    //             assertions: e,
    //             incidence_id: incidence_id
    //         }]
    //     }
    //
    //     setSelectedData({
    //         ...selectedData,
    //         incidenceTab: updateList
    //     })
    // }

    const handleIncidenceRecommandation = (value, incidence_id) => {
        const arrayList = selectedData.incidenceTab;

        let updateList
        if(arrayList){
            const incidenceItem  = arrayList.find((item) => item.incidence_id === incidence_id)
            if(incidenceItem){
                updateList = arrayList.map((item) =>
                    item.incidence_id === incidence_id ? { ...item, recommandation: value } : item
                );
            }
            else{
                const newElement = {
                    // id: incidence_id,
                    niveauResiduel_id: '',
                    recommandation: value,
                    conclusion: '',
                    assertions: [],
                    incidence_id: incidence_id
                };

                updateList = [...arrayList, newElement]
            }

        }
        else {
            updateList = [{
                // id: incidence_id,
                niveauResiduel_id: '',
                recommandation: value,
                conclusion: '',
                assertions: [],
                incidence_id: incidence_id
            }]
        }

        setSelectedData({
            ...selectedData,
            incidenceTab: updateList
        })
    }

    const handleIncidenceConclusion = (value, incidence_id) => {
        const arrayList = selectedData.incidenceTab;

        let updateList
        if(arrayList){
            const incidenceItem  = arrayList.find((item) => item.incidence_id === incidence_id)
            if(incidenceItem){
                updateList = arrayList.map((item) =>
                    item.incidence_id === incidence_id ? { ...item, conclusion: value } : item
                );
            }
            else{
                const newElement = {
                    // id: incidence_id,
                    niveauResiduel_id: '',
                    recommandation: '',
                    conclusion: value,
                    assertions: [],
                    incidence_id: incidence_id
                };

                updateList = [...arrayList, newElement]
            }

        }
        else {
            updateList = [{
                // id: incidence_id,
                niveauResiduel_id: '',
                recommandation: '',
                conclusion: value,
                assertions: [],
                incidence_id: incidence_id
            }]
        }

        setSelectedData({
            ...selectedData,
            incidenceTab: updateList
        })
    }

    const columnsRisque = [
        {
            title: () => <TableTitle>Incidence (effet) du facteur de risque sur les EF</TableTitle>,
            dataIndex: 'incidence',
            width: 400,
            render: (_, record) => (
                <span className='' style={{fontSize: '14px'}}>
                  {record.incidence}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Assertions</TableTitle>,
            dataIndex: 'assertions',
            width: 100,
            render: (_, record) => (
                <Space>
                    {record.assertions?.map((assertion, index) => (
                        <Tag key={index}>
                            <span className='fw-bolder' >
                                {assertion}
                            </span>
                        </Tag>
                    ))}
                </Space>
            ),
        },
        {
            title: () => <TableTitle>Niveau Risque</TableTitle>,
            dataIndex: 'niveau_id',
            width: 100,
            render: (_, record) => (
                <Tag color={record.score < 6 ? "success" : record.score <= 15 ? "warning" : "red"}>
                    <span style={{fontFamily: "Oswald, sans-serif"}}  className='fw-bolder' >
                        {niveaux.find((niveau) => niveau.id === record.niveau_id)?.libelle}
                    </span>
                </Tag>
            ),
        },
    ];

    const columnsProcedures = [
        {
            title: () => <TableTitle>Procédures</TableTitle>,
            dataIndex: 'libelleProcedure',
            width: 500,
            render: (_, record) => (
                <span className='' style={{fontSize: '14px'}}>
                  {record.procedure}
                </span>
            ),
        },
    ];

    const columnDescription = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Description des points de contrôles</TableTitle>,
            dataIndex: 'description',
            width: 400,
            render: (_, record, index) => (
                <div className="">
                    <Input.TextArea
                        style={{fontSize: "14px"}}
                        size="large"
                        bordered={false}
                        placeholder="Saisisser la procédure..."
                        value={record.description}
                        maxLength={3000}
                        // showCount
                        autoSize={{
                            minRows: 2,
                            maxRows: 6,
                        }}
                        onChange={(e) => {
                            const updatedData = [...selectedData.descriptionsData];
                            updatedData.filter(data => data.procedure_id === incidence?.procedure?.id)[index].description = e.target.value

                            setSelectedData({
                                ...selectedData,
                                descriptionsData: updatedData
                            })
                        }}
                    />
                </div>
            ),
        },
        {
            title: () => <TableTitle>Résultat / Constatations</TableTitle>,
            dataIndex: 'resultat',
            width: 400,
            render: (_, record, index) => (
                <Input.TextArea
                    style={{fontSize: "14px"}}
                    size="large"
                    bordered={false}
                    placeholder="Saisisser la procédure..."
                    value={record.resultat}
                    maxLength={3000}
                    // showCount
                    autoSize={{
                        minRows: 2,
                        maxRows: 6,
                    }}
                    onChange={(e) => {
                        const updatedData = [...selectedData.descriptionsData];
                        updatedData.filter(data => data.procedure_id === incidence?.procedure?.id)[index].resultat = e.target.value

                        setSelectedData({
                            ...selectedData,
                            descriptionsData: updatedData
                        })
                    }}
                />
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: 70,
            render: (_, record) => (
                <>
                    <Button
                        onClick={() => handleDeleteElement(record.question_id)}
                        title="Supprimer ?"
                        type="primary"
                        icon={<DeleteOutlined />}
                        danger
                    />
                </>
            ),
        },
    ];

    const handleRowClick = (record) => {
        return new Promise((resolve) => {
            setLoading(true);
            setTimeout(() => {
                setIncidence({
                    ...record,
                    procedure: (selectedData.proceduresData ?? []).filter(proc => proc.incidence_id === record.id)[0],
                });

                setLoading(false);
                resolve();
            }, 1000);
        });
    };

    const handleRowClickProcedure = (record) => {
        return new Promise((resolve) => {
            setLoading(true);
            setTimeout(() => {
                setIncidence({
                    ...incidence,
                    procedure: record,
                });

                setLoading(false);
                resolve();
            }, 1000);
        });
    };

    return (
        <>
            <div className="modal-body">
                <Row gutter={[16, 16]}>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                    >
                        <div className="table-responsive">
                            <DynamicTable
                                columns={columnsRisque}
                                dataSource={risqueIncidences}
                                loading={chargement}
                                onRowClickHandler={handleRowClick}
                            />
                        </div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                    >
                        <Row gutter={[16, 16]}>
                            <Col
                                xs={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 24 }}
                                xl={{ span: 24 }}
                            >
                                <div className="table-responsive">
                                    <DynamicTable
                                        columns={columnsProcedures}
                                        dataSource={(selectedData?.proceduresData ?? []).filter(proc => proc.incidence_id === incidence.id)}
                                        loading={chargement || loading}
                                        onRowClickHandler={handleRowClickProcedure}
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 24 }}
                                xl={{ span: 24 }}
                            >
                                <Title className="my-5" style={{display: "block", fontWeight: '600', color: appTheme.colors.primary}} level={4}># {"Incidence sélectionnée"}</Title>
                                {
                                    loading ?
                                        <div className="d-flex justify-content-center align-items-center my-auto">
                                            <Spinner/>
                                        </div>
                                        :
                                        <Row>
                                            <Col span={24}>
                                                <Row gutter={[16, 16]}>
                                                    <Col
                                                        xs={{ span: 24 }}
                                                        md={{ span: 24 }}
                                                        lg={{ span: 12 }}
                                                        xl={{ span: 12 }}
                                                    >
                                                        <FormLabel label={"Incidence (effet) du facteur de risque sur les EF"} />
                                                        <Input.TextArea
                                                            style={{fontSize: "14px"}}
                                                            size="large"
                                                            bordered={false}
                                                            value={incidence.incidence}
                                                            maxLength={3000}
                                                            // showCount
                                                            autoSize={{
                                                                minRows: 2,
                                                                maxRows: 8,
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col
                                                        xs={{ span: 24 }}
                                                        md={{ span: 24 }}
                                                        lg={{ span: 12 }}
                                                        xl={{ span: 12 }}
                                                    >
                                                        <FormLabel label={"Procédure"} />
                                                        <Input.TextArea
                                                            style={{fontSize: "14px"}}
                                                            size="large"
                                                            bordered={false}
                                                            value={incidence?.procedure?.procedure || ""}
                                                            maxLength={3000}
                                                            // showCount
                                                            autoSize={{
                                                                minRows: 2,
                                                                maxRows: 8,
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <br/>
                                            <Col
                                                xs={{ span: 12 }}
                                                md={{ span: 12 }}
                                                lg={{ span: 8 }}
                                                xl={{ span: 8 }}
                                            >
                                                <FormLabel label={"Assertions"} />
                                                <div className='d-flex align-center' >
                                                    {(incidence?.assertions ?? []).map((assertion, index) => (
                                                        <Space>
                                                            <Tag key={index}>
                                                                <span className='fw-boldest' key={index}>
                                                                    {assertion}
                                                                </span>
                                                            </Tag>
                                                        </Space>
                                                    ))}
                                                </div>
                                            </Col>
                                            <Col
                                                xs={{ span: 12 }}
                                                md={{ span: 12 }}
                                                lg={{ span: 8 }}
                                                xl={{ span: 8 }}
                                            >
                                                <FormLabel label={"Niveaux de risque"} />
                                                <Tag color={incidence.score < 6 ? "success" : incidence.score <= 15 ? "warning" : "red"}>
                                                    <span style={{fontFamily: "Oswald, sans-serif"}}  className='fw-bolder' >
                                                        {niveaux.find((niveau) => niveau.id === incidence.niveau_id)?.libelle}
                                                    </span>
                                                </Tag>
                                            </Col>
                                            <Col
                                                xs={{ span: 12 }}
                                                md={{ span: 12 }}
                                                lg={{ span: 8 }}
                                                xl={{ span: 8 }}
                                            >
                                                <FormLabel label={"Postes des EF concernés"} />
                                                <div className="d-flex flex-column">
                                                    {(incidence?.postes ?? []).map((poste, index) => (
                                                        poste ? <div className="mb-2" key={index}>
                                                                <Tag
                                                                    style={{fontSize: "12px", fontWeight: "700", textTransform: "uppercase"}}
                                                                >
                                                                    {`${poste} - ${(postesStat ?? []).find( opt => opt.key === poste)?.title}`}
                                                                </Tag>
                                                            </div>
                                                            :
                                                            ""
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>
                                }
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <div className="mb-15"></div>
                <div className="d-flex justify-content-end align-center my-2 me-5">
                    <div className="d-flex flex-wrap align-center">
                        <Space>
                            {(mission.isCloture !== 1 || mission.isClotureProvisoire !==1) && <Tooltip color={appTheme.colors.primary} title="Ajouter une nouvelle question">
                                <Button
                                    size={"middle"}
                                    style={{color: "white", backgroundColor: appTheme.colors.primary}}
                                    onClick={handleAdd}
                                    icon={<FontAwesomeIcon icon={faCartPlus}/>}
                                >
                                    Ajouter
                                </Button>
                            </Tooltip>}
                        </Space>
                    </div>
                </div>
                <div className="table-responsive pt-5 pb-10">
                    <Table
                        rowClassName={() =>
                            `h-3px p-0 m-0 lh-0.5`
                        }
                        columns={columnDescription}
                        // rowKey={(record, index) => index}
                        dataSource={(selectedData?.descriptionsData ?? []).filter(data => data.procedure_id === incidence?.procedure?.id) ?? []}
                        pagination={false}
                        // scroll={{y: 800}}
                        loading={chargement || loading}
                        // rowSelection
                        size={"small"}
                    />
                </div>
            </div>
            <div className='modal-body pt-10'>
                <div className="card shadow-sm">
                    <div className="card-body">
                        <Row gutter={[16, 16]}>
                            <Col
                                xs={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: 8 }}
                                xl={{ span: 8 }}
                            >
                                <FormLabel label="Niveau de risque résiduel" />
                                <Select
                                    showSearch
                                    placeholder="Sélectionner le niveau de risque..."
                                    optionFilterProp="label"
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    options={niveaux.map((niv) => ({
                                        value: niv.id,
                                        label: niv.libelle,
                                    }))}
                                    value={(selectedData?.incidenceTab ?? []).find(select => select.incidence_id === incidence.id)?.niveauResiduel_id || null}
                                    onChange={(value) =>
                                        handleIncidenceNiveau(value, incidence.id)
                                    }
                                    size="large"
                                    style={{ width: "100%" }}
                                />
                            </Col>
                            <Col span={2}></Col>
                            <Col
                                xs={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: 4 }}
                                xl={{ span: 4 }}
                            >
                                <FormLabel label={"Assertions"} />
                                <div className='d-flex align-center' >
                                    {(incidence?.assertions ?? []).map((assertion, index) => (
                                        <Space>
                                            <Tag key={index}>
                                                <span className='fw-boldest' key={index}>
                                                    {assertion}
                                                </span>
                                            </Tag>
                                        </Space>
                                    ))}
                                </div>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: 6 }}
                                xl={{ span: 6 }}
                            >
                                <FormLabel label={"Postes des EF concernés"} />
                                <div className="d-flex flex-column">
                                    {(incidence?.postes ?? []).map((poste, index) => (
                                        poste ? <div className="mb-2" key={index}>
                                                <Tag
                                                    style={{fontSize: "12px", fontWeight: "700", textTransform: "uppercase"}}
                                                >
                                                    {`${poste} - ${(postesStat ?? []).find( opt => opt.key === poste)?.title}`}
                                                </Tag>
                                            </div>
                                            :
                                            ""
                                    ))}
                                </div>
                            </Col>
                        </Row>
                        <div className="mt-10"></div>
                        <Row gutter={[16, 16]}>
                            <Col
                                xs={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: 24 }}
                                xl={{ span: 24 }}
                            >
                                <div className="d-flex flex-column mb-3 mx-2">
                                    <FormLabel label="Recommandation" />
                                    <Input.TextArea
                                        style={{fontSize: "14px"}}
                                        size="large"
                                        placeholder="Saisisser la recommandation..."
                                        value={(selectedData?.incidenceTab ?? []).find(select => select.incidence_id === incidence.id)?.recommandation || null}
                                        maxLength={5000}
                                        showCount
                                        autoSize={{
                                            minRows: 6,
                                            maxRows: 8,
                                        }}
                                        onChange={(e) =>handleIncidenceRecommandation(e.target.value, incidence.id)}
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 24 }}
                                xl={{ span: 24 }}
                            >
                                <div className="d-flex flex-column mb-3 mx-2">
                                    <FormLabel label="CONCLUSION" />
                                    <Input.TextArea
                                        style={{fontSize: "14px"}}
                                        size="large"
                                        placeholder="Saisisser la conclusion..."
                                        value={(selectedData?.incidenceTab ?? []).find(select => select.incidence_id === incidence.id)?.conclusion || ''}
                                        maxLength={5000}
                                        showCount
                                        autoSize={{
                                            minRows: 6,
                                            maxRows: 8,
                                        }}
                                        onChange={(e) =>handleIncidenceConclusion(e.target.value, incidence.id)}
                                    />
                                    <div className='mt-5'>
                                        <div className="">
                                            <Checkbox
                                                onChange={() => setIsAddMemoire(!isAddMemoire)}
                                                value={isAddMemoire}
                                            >
                                                Aide memoire
                                            </Checkbox>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {/*<div className="card-body">*/}
                    {/*    <div className="mb-3 d-md-flex align-items-center">*/}
                    {/*        <div className='col-md-6 mb-3 mb-md-0'>*/}
                    {/*            <label className="form-label">Niveau de risque résiduel</label>*/}
                    {/*            <select*/}
                    {/*                className="form-select mb-2 w-100 w-lg-300px fs-7"*/}
                    {/*                data-control="select2"*/}
                    {/*                data-select2-id="select2-data-16-9pfx"*/}
                    {/*                tabIndex="-1"*/}
                    {/*                value={incidenceSelected?.niveauResiduel_id || ''}*/}
                    {/*                onChange={(e) => handleIncidenceNiveau(e, incidence.id)}*/}
                    {/*            >*/}
                    {/*                <option value=''>Sélectionner...</option>*/}
                    {/*                {niveaux.map((opt) => (*/}
                    {/*                    <option value={opt.id} key={opt.id}>*/}
                    {/*                        {opt.libelle}*/}
                    {/*                    </option>*/}
                    {/*                ))}*/}
                    {/*            </select>*/}
                    {/*        </div>*/}

                    {/*        <div className="fv-row flex-row-fluid fv-plugins-icon-container mb-3 ms-lg-5">*/}
                    {/*            <label className="form-label">Assertions</label>*/}
                    {/*            <div className='d-flex align-items-center'>*/}
                    {/*                {incidence.assertions?.map((assertion) => (*/}
                    {/*                    <span className='fw-bolder me-3' key={assertion.id}>*/}
                    {/*                            {assertion}*/}
                    {/*                        </span>*/}
                    {/*                ))}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*        <div className="fv-row flex-row-fluid fv-plugins-icon-container mb-3">*/}
                    {/*            <label className="form-label">Postes concernés</label>*/}
                    {/*            <div className='d-flex flex-column align-items-center'>*/}
                    {/*                {incidence.postes?.map((poste, index) => (*/}
                    {/*                    <span className='fw-bolder me-3' key={index}>*/}
                    {/*                            {`${poste} - ${sommaires.find((code) => code.libelle?.includes(poste))?.description}`}*/}
                    {/*                        </span>*/}
                    {/*                ))}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className="mb-10">*/}
                    {/*        <label className="required form-label">Recommandation</label>*/}
                    {/*        <textarea*/}
                    {/*            className='form-control mb-2'*/}
                    {/*            rows="50"*/}
                    {/*            cols="150"*/}
                    {/*            value={incidenceSelected?.recommandation || ''}*/}
                    {/*            style={{*/}
                    {/*                height: '75px',*/}
                    {/*                marginBottom: 24,*/}
                    {/*                fontSize: '11px'*/}
                    {/*            }}*/}
                    {/*            onChange={(e) =>handleIncidenceRecommandation(e, incidence.id)}*/}
                    {/*        >*/}
                    {/*        </textarea>*/}
                    {/*    </div>*/}

                    {/*</div>*/}
                </div>
            </div>
            
           
        </>
    );
}

export { TraitementControllesFonctionn};