import React, {Fragment} from "react";
import {notification, Space, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {appTheme} from "../../config/theme";
import {faLock, faSave} from "@fortawesome/free-solid-svg-icons";

const ModalNavBarMenu = ({
         steps,
         setActiveTab,
         activeTab,
         loading,
         setLoading,
         isTaskSave,
         auth,
     }) => {
    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = () => {
        api['warning']({
            message: 'Attention! Cycle verrouillé.',
            description:
                'Veuillez vous identifier !',
        });
    };

    const handleTabChange = (tabIndex) => {
        if(!auth){
            openNotificationWithIcon()
            return setActiveTab(0);
        }
        if((isTaskSave ?? []).some(item => item === tabIndex)){
            notification.warning({
                description: "Le traitement de ce onglet n'a pas été enregistrer.",
            });
        }

        setLoading(true);
        setTimeout(() => {
            setActiveTab(tabIndex);
            setLoading(false);
        }, 1000)
    };

    return (
        <Fragment>
            {contextHolder}
            {/*<ModalNavBarMenu setActiveTab={setActiveStep} activeTab={activeStep} steps={formSteps}/>*/}

            <div className="mb-2 mx-3 scroll-x">
                <Space>
                    {steps?.map((step, index) => (
                        <>
                            <div className="app-nav-menu-option">
                                <Tooltip key={index} color="" title={step?.description || ''}>
                                    <div
                                        className="border-0"
                                        onClick={() => handleTabChange(index)}
                                    >
                                        <div className="d-flex justify-content-between">
                                            <FontAwesomeIcon className="app-nav-menu-option-icon" style={{marginRight: "10px", fontSize: "18px", color: activeTab !== index ? "darkgrey" : ""}} icon={step.icon} />
                                            <Space>
                                                {(isTaskSave ?? [])?.some(item => item === index) ? <FontAwesomeIcon className="" style={{fontSize: "14px", color: "red"}} icon={faSave} /> : ""}
                                                {!auth && index > 0 && <Tooltip title="Cycle verrouillé. Veuillez vous identifier" color="red">
                                                    <div><FontAwesomeIcon icon={faLock}
                                                                          style={{color: 'red', fontSize: '12px'}}/></div>
                                                </Tooltip>}
                                            </Space>
                                        </div>
                                        <span style={{color: activeTab !== index ? "darkgrey" : ""}} className="app-nav-menu-option-title d-block text-uppercase">{step.title}</span>
                                    </div>
                                </Tooltip>
                                {activeTab === index && <div style={{
                                    backgroundColor: appTheme.colors.primary,
                                    width: "100%",
                                    height: "3px",
                                    marginTop: "3px"
                                }}></div>}
                            </div>
                            {steps[index + 1]  && <div style={{backgroundColor: appTheme.colors.primary, width: "100px", height: "2px"}}></div>}
                        </>
                    ))}
                </Space>
            </div>
        </Fragment>
    )

}

export default ModalNavBarMenu;