import React, {useEffect, useState} from "react";
import { Identification } from "../../Identification";
import {CyclesRevue} from "./proceduresModal/auditInitial/CyclesRevue";
import {AuditInitialObservation} from "./proceduresModal/auditInitial/AuditInitialObservation";
import {AuditInitialConclusion} from "./proceduresModal/auditInitial/AuditInitialConclusion";
import {
    ajouterProcedureMissionAudit,
    getProcedureMissionAudit,
} from "../../../../../data/procedureAudit";
import {getListeIdentificationSommaire} from "../../../../../data/rateau";
import swal from "sweetalert";
import {succesMessage} from "../../../../../helpers/AssetsHelpers";
import {faIdCard, faSave, faTasks} from "@fortawesome/free-solid-svg-icons";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import {Button, Tooltip} from "antd";
import {appTheme} from "../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PrintAuditInitial from "./proceduresModal/auditInitial/PrintAuditInitial";

const ControleMissionAuditInitiale = ({
          mission,
          client,
          code,
          // modalId,
          prev
      }) => {
    const [activeTab, setActiveTab] = useState(2);
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [codeSommaire] = useState(code || 'EE3.17');
    const [date, setDate] = useState('');
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);
    const [identifications, setIdentifications] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectYear, setSelectYear] = useState(mission.annee || "");
    const [missionSelected, setMissionSelected] = useState(mission || null);
    const [isProvisoire, setIsProvisoire] = useState('1');
    const [isTaskSave, setIsTaskSave] = useState([]);

    const [conclusion, setConclusion] = useState('')
    const [observation, setObservation] = useState('')
    const [conclusionGenerale, setConclusionGenerale] = useState('')
    const [datas, setDatas] = useState([]);
    const [travauxData, setTravauxData] = useState([]);
    const [isAddMemoire, setIsAddMemoire] = useState(false)
    const [excelPrintData, setExcelPrintData] = useState([]);

    useEffect(()=> {
        getProcedureMissionAudit(missionSelected.id)
            .then((res) => {
                if(res.result){
                    const noteFetch = res.result.codeSommaire === codeSommaire
                    if(noteFetch){
                        setObservation(res.result.observation)
                        setConclusion(res.result.conclusion)
                        setConclusionGenerale(res.result.conclusionGenerale)
                        setDatas(res.result?.Lea_ProcedureMissionAuditQuestions);
                    }
                    else{
                        setObservation('')
                        setConclusion('')
                        setConclusionGenerale('')
                        setDatas([]);
                    }
                }
            })
    }, [codeSommaire, missionSelected])

    useEffect(()=> {
        if(activeTab === 0){
            getListeIdentificationSommaire(missionSelected.id, codeSommaire)
                .then((res) => {
                    setIdentifications(res.result)
                })
        }
    }, [activeTab, codeSommaire, missionSelected])

    useEffect(() => {
        if((travauxData ?? []).length > 0){
            const data = travauxData.flatMap((item) =>
                item.questions.map((question) => ({
                    question_id: question.question_id,
                    groupe: question.groupe,
                    ref: question.ref,
                    libelle: question.libelle,
                    reponse: question.reponse,
                    refActivite: question.refActivite,
                    assertions: question.assertions,
                    commentaire: question.commentaire
                }))
            );
            setExcelPrintData(data)
        }
    }, [travauxData])

    const handleSubmit = async (e) => {
        e.preventDefault()

        let travaux = travauxData.flatMap((item) =>
            item.questions.map((question) => ({
                question_id: question.question_id,
                groupe: question.groupe,
                ref: question.ref,
                libelle: question.libelle,
                reponse: question.reponse,
                refActivite: question.refActivite,
                assertions: question.assertions,
                commentaire: question.commentaire
            }))
        );

        const data = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            travauxData: travaux,
            conclusion,
            conclusionGenerale,
            isAddMemoire,
            isProvisoire,
            observation,
            codeSommaire: code,
            exercice_id: missionSelected.id,
            client_id: missionSelected.client_id
        }

        const {status, message} = await ajouterProcedureMissionAudit(data)
        if (status === 'success') {
            setLoading(false)
            await swal(succesMessage, '', 'success')
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    }

    const formSteps = [
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: (
                <Identification
                    identifications={identifications}
                    mission={missionSelected}
                />
            ),
        },
        {
            code: "NT",
            title: "Notes",
            icon: faTasks,
            content: (
                <div className="card mb-6 mb-xl-9">
                    <div className="card-body">

                        <div className="mb-2">
                            <span className="fs-4 fw-bolder mb-1 text-gray-900 text-hover-primary">Mode de remplissage du questionnaire: </span>
                        </div>
                        <div className="fs-6 fw-bold text-gray-600 mb-5">
                            Le questionnaire est rempli par le(s) membre(s) de l'équipe chargé(s) de la revue. Toute réponse "non" devrait être assortir de commentaire <br/>
                            Le présent questionnaire a pour objectif d'apprécier si les travaux mis en oeuvres par les co-commissaires au compte: <br/>
                            <br/>
                            <strong>-</strong> correspondent à ceux définis lors de la répartitions ou décidés lors de la réévaluation du risque d'anomalies significative au niveau des assertions; <br/>
                            <strong>-</strong> ont permis de collecter des éléments suffisant et appropriés pour permettre d'aboutir à des conclusions à partir desquelles [le signataire] pourra fonder son opinion sur les comptes; <br/>
                            <strong>-</strong> les conclusions auxquelles les co-commissaires aux comptes ont abouti sont permanentes et cohérentes <br/>
                            <br/>
                            Ce questionnaire doit être renseigné en tenant compte des travaux réalisés pour les comptes annuels et pour les comptes consolidés (le cas échéant).<br/>
                            Le commissaire aux comptes peut, s'il le juge utile, renseigner deux questionnaires : l'un pour les comptes annuels, l'autre comptes consolidés
                        </div>
                    </div>
                </div>
            ),
        },
        {
            code: "CR",
            title: "Cycles revus",
            icon: faTasks,
            content: (
                <>
                    <CyclesRevue
                        client={client}
                        isProvisoire={isProvisoire}
                        setIsAddMemoire={setIsAddMemoire}
                        isAddMemoire={isAddMemoire}
                        setConclusion={setConclusion}
                        conclusion={conclusion}
                        selectItems={travauxData}
                        setSelectItems={setTravauxData}
                        datas={datas}
                        chargement={loading}
                        mission={missionSelected}
                        setIsTaskSave={setIsTaskSave}
                    />
                </>
            ),
        },
        {
            code: "OG",
            title: `Observation générale - ${selectYear}`,
            description: `Observation générale - ${selectYear}`,
            icon: faTasks,
            content: (
                <AuditInitialObservation
                    observation={observation}
                    setObservation={setObservation}
                    setIsTaskSave={setIsTaskSave}
                />
            ),
        },
        {
            code: "CC",
            title: `Conclusion - ${selectYear}`,
            description: `Conclusion - ${selectYear}`,
            icon: faTasks,
            content: (
                <AuditInitialConclusion
                    setConclusionGenerale={setConclusionGenerale}
                    conclusionGenerale={conclusionGenerale}
                    setIsTaskSave={setIsTaskSave}
                />
            ),
        },
    ];

    return (
        <>
            <div className="mx-5">
                <PageToolbarHeader
                    prev={prev} codeSommaire={codeSommaire}
                    pageTitle={"EE3.17 - Mission d'audit initiale "}
                    mission={missionSelected}
                    setMission={setMissionSelected}
                    isProvisoire={isProvisoire}
                    setIsProvisoire={setIsProvisoire}
                    selectYear={selectYear}
                    setSelectYear={setSelectYear}
                    loading={loading}
                    setLoading={setLoading}
                    client={client}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    setDate={setDate}
                    setTache_id={setTache_id}
                    tache_id={tache_id}
                    setIntervention_id={setIntervention_id}
                    intervention_id={intervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    isExcelPrint={true}
                    isLandingScape={true}
                    printTitle={(`EE3.17 - Mission d'audit initiale ${selectYear}`)}
                    excelData={excelPrintData}
                    printContent={(
                        <PrintAuditInitial
                            objectif={`
                                            Le questionnaire est rempli par le(s) membre(s) de l'équipe chargé(s) de la revue. Toute réponse "non" devrait être assorti de commentaire
                                            Le présent questionnaire a pour objectif d'apprécier si les travaux mis en œuvres par les co-commissaires au compte :
                                            correspondent à ceux définis lors de la répartition ou décidés lors de la réévaluation du risque d'anomalies significative au niveau des assertions ;
                                            ont permis de collecter des éléments suffisants et appropriés pour permettre d'aboutir à des conclusions à partir desquelles [le signataire] pourra fonder son opinion sur les comptes;
                                            les conclusions auxquelles les co-commissaires aux comptes ont abouti sont permanentes et cohérentes
                                            Ce questionnaire doit être renseigné en tenant compte des travaux réalisés pour les comptes annuels et pour les comptes consolidés (le cas échéant).
                                            Le commissaire aux comptes peut, s'il le juge utile, renseigner deux questionnaires : l'un pour les comptes annuels, l'autre compte consolidés
                                        `}
                            datas={travauxData}
                            conclusion={conclusionGenerale}
                            observation={observation}
                        />
                    )}
                />
                <StepHeader>
                    <ModalNavBarMenu
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        steps={formSteps}
                        setLoading={setLoading}
                        loading={loading}
                    />
                </StepHeader>
                <StepBody>
                    <>
                        {activeTab >= 2 && <div className="d-flex justify-content-end align-items-end mb-10">
                            <Tooltip title="Enregistrer">
                                <Button
                                    disabled={(missionSelected.isCloture === 1 || missionSelected.isClotureProvisoire === 1)}
                                    size={"large"}
                                    // loading={loading}
                                    style={{
                                        backgroundColor: appTheme.colors.secondary,
                                        color: "white"
                                    }}
                                    icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                                    onClick={handleSubmit}
                                >
                                    {!loading &&
                                        <span className='indicator-label text-white'>Enregistrer</span>}
                                    {loading && (
                                        <span className='indicator-progress fs-9 text-white'
                                              style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                    )}
                                </Button>
                            </Tooltip>
                        </div>}
                        {formSteps[activeTab].content}
                    </>
                </StepBody>
            </div>
        </>
    );
}

export {ControleMissionAuditInitiale}