import React, {useState} from "react";
import swal from "sweetalert";
import {succesMessage} from "../../../../../../../helpers/AssetsHelpers";
import {ajouterProcedureAudit} from "../../../../../../../data/procedureAudit";
import {Button, Input, Tooltip} from "antd";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import FormLabel from "../../../../../../common/Form/FormLabel";

const ObservationGenerale = ({observation, setObservation, mission, isProvisoire, code, chargement, setIsTaskSave}) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault()

        const data = {
            isProvisoire,
            observation,
            codeSommaire: code,
            exercice_id: mission.id,
            client_id: mission.client_id
        }

        const {status, message} = await ajouterProcedureAudit(data)
        if (status === 'success') {
            setLoading(false)
            await swal(succesMessage, '', 'success')
            // setIsTaskSave(false);
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    }

    return (
        <div className="flex-column fv-plugins-bootstrap5 p-5 fv-plugins-framework">
            <div className="d-flex justify-content-end align-items-end mb-5">
                <Tooltip title="Enregistrer">
                    <Button
                        disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                        size={"large"}
                        // loading={loading}
                        style={{
                            backgroundColor: appTheme.colors.secondary,
                            color: "white"
                        }}
                        icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                        onClick={handleSubmit}
                    >
                        {!(loading || chargement) &&
                            <span className='indicator-label text-white'>Enregistrer</span>}
                        {(loading || chargement) && (
                            <span className='indicator-progress fs-9 text-white'
                                  style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                        )}
                    </Button>
                </Tooltip>
            </div>
            <div className="d-flex flex-column mb-3 mx-2">
                <FormLabel label="Observation générale" />
                <Input.TextArea
                    style={{fontSize: "14px"}}
                    size="large"
                    placeholder="Saisisser l'observation..."
                    value={observation}
                    maxLength={5000}
                    showCount
                    autoSize={{
                        minRows: 8,
                        maxRows: 10,
                    }}
                    onChange={(e) => {
                        setObservation(e.target.value)
                        // setIsTaskSave(true);
                    }}
                />
            </div>
        </div>
    )
}

export {ObservationGenerale}