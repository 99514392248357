import {Button, Col, Input, notification, Row, Select, Space, Table, Tag, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {getListeAssertionActive} from "../../../../../../../data/params/assertion";
import { DeleteOutlined} from "@ant-design/icons";
import {getListeProgramme} from "../../../../../../../data/params/programme";
import {TravauxModal} from "./TravauxModal";
import swal from "sweetalert";
import {ajouterTravauxAudit} from "../../../../../../../data/procedureAudit";
import {getDetailsRisqueEvaluationAll} from "../../../../../../../data/risqueEvaluation";
import {areArraysEqual} from "../../../../../../utilities/Utilities";
import TableTitle from "../../../../../../common/TableTitle";
import {ModalTitle} from "../../../../../../modals/modals.components";
import {FormContainer} from "../../../../../../common/common.components";
import SearchRow from "../../../../../../common/SearchRow";
import FormLabel from "../../../../../../common/Form/FormLabel";
import ModalAlert from "../../../../../../modals/Modal";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudUpload, faEdit, faSave} from "@fortawesome/free-solid-svg-icons";

const ModalProgrammeStandard = ({
        handleVisibility,
        visible,
        selectData,
        setSelectData,
        code,
        assertData,
        assertions,
        chargement,
    }) => {
    const [loading, setLoading] = useState(false);
    const [programmes, setProgrammes] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [index, setIndex] = useState(1);
    const [programmesTravail, setProgrammesTravail] = useState([]);
    const [assertionsData, setAssertionsData] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);

    const [paramsRecherche, setParamsRecherche] = useState({
        question: "",
        assertion: "",
    });

    useEffect(() => {
        const asserts = assertions.filter((item) =>
            (assertData ?? []).includes(item.libelle)
        );
        setAssertionsData(asserts);
    }, [assertData, assertions])

    useEffect(() => {
        const fetchProgramme = async () => {
            try {
                const res = await getListeProgramme();
                if (res.result) {
                    const updateList = res.result.filter((item) =>
                        item.codeSommaire === code &&
                        item.assertions.some((assert) =>
                            (assertData ?? []).includes(assert)
                        )
                    );

                    if (updateList.length > 0) {
                        setProgrammes(updateList);
                        setFilteredItems(updateList);
                    }
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des événements risques', error);
            }
        };

        if (code && assertData) {
            fetchProgramme();
        }
    }, [assertData, code]);

    console.log("programmesTravail", programmes)
    console.log("codeTravail", code)

    // const handleAssertionChange = (e) => {
    //     setAssertion(e.target.value)
    //     const updatedQuestions = programmes.filter((programme) => programme.assertions.includes(e.target.value))
    //     setFilteredItems(updatedQuestions)
    // }

    const toggleCheckbox = (id) => {
        const selectedList = [...programmesTravail];
        const valueId = parseInt(id);

        if (isNaN(valueId)) {
            return;
        }

        const add = programmes.find((item) => item.id === valueId);

        const isAlreadySelected = programmesTravail.some((element) => element.programme_id === add.id);

        if (!isAlreadySelected) {
            const newElement = {
                programme_id: add.id,
                ref: add.reference,
                libelle: add.libelle,
                assertions: add.assertions,
            };

            setProgrammesTravail([...selectedList, newElement]);
        }
        else {
            const selectedIndex = selectedList.findIndex((item) => item.programme_id === (add.id));

            const updatedList = selectedList.filter((_, i) => i !== selectedIndex);
            setProgrammesTravail(updatedList)
        }

    };

    const onSelectAll = () => {
        setLoading(true);
        setTimeout(() => {
            const selectedList = [...programmesTravail];
            programmes.forEach((prog) => {
                const isAlreadySelected = selectedList.some((element) => element.programme_id === prog.id);

                if (!isAlreadySelected) {
                    const newElement = {
                        programme_id: prog.id,
                        ref: prog.reference,
                        libelle: prog.libelle,
                        assertions: prog.assertions,
                    };
                    selectedList.push(newElement)
                }
            })
            setProgrammesTravail(selectedList)

            setIsAllSelected(true);
            setLoading(false);
        }, 1000)
    };

    const handleDeleteAllElements = () => {
        setLoading(true);
        setTimeout(() => {
            setProgrammesTravail([]);
            setIsAllSelected(false);
            setLoading(false);
        }, 1000)
    };

    const handleValidate = () => {
        if(programmesTravail.length > 0 ) {
            setLoading(true);
            setTimeout(() => {
                const updateList = []
                programmesTravail.forEach((programme) => {

                    const newElement = {
                        id: programme.programme_id,
                        programme_id: programme.programme_id,
                        ref: programme.ref,
                        libelle: programme.libelle,
                        assertions: programme.assertions,
                        commentaire: '',
                        conclusion: ''
                    };

                    updateList.push(newElement)
                    setIndex(index + 1)
                })

                setSelectData([...selectData, ...updateList]);

                handleVisibility();
                notification.success({
                    description: "Question ajouté avec succès !",
                });

                setLoading(false);
            }, 1500)
        }
    }

    const columsProgrammes = [
        {
            title: () => <TableTitle>REF</TableTitle>,
            dataIndex: 'reference',
            width: 40,
            render: (_, record) => (
                <Tag>
                    <span className='fw-bolder' style={{fontSize: '13px'}}>
                        {record.reference}
                    </span>
                </Tag>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'libelle',
            width: 250,
            render: (_, record, index) => (
                <span className='' key={index} style={{fontSize: '13px'}}>
                    {record.libelle}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Assertions</TableTitle>,
            dataIndex: 'assertions',
            width: 50,
            render: (_, record) => (
                <Space>
                    {record.assertions?.map((assertion, index) => (
                        <Tag key={index}>
                            <span className='fw-bolder' >
                                {assertion}
                            </span>
                        </Tag>
                    ))}
                </Space>
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            width: 50,
            render: (_, record) => (
                <span className="form-check form-check-custom me-5">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="product"
                        data-kt-product-name="Basic"
                        checked={
                            programmesTravail.some((item) => item.programme_id === record.id)
                        }
                        onChange={() => {toggleCheckbox(record.id)}}
                    />
              </span>
            ),
        },
    ];

    const columnsSelectQuestions = [
        {
            title: () => <TableTitle>Question</TableTitle>,
            dataIndex: 'libelle',
            width: 450,
            render: (_, record) => (
                <div className="d-flex align-center">
                    <div className="me-2">
                        <Tag>
                            <span className='fw-boldest' style={{fontSize: '12px'}}>
                                {record.ref}
                            </span>
                        </Tag>
                    </div>
                    <span className='' style={{fontSize: '13px'}}>
                        {record.libelle}
                    </span>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Assertions</TableTitle>,
            dataIndex: 'assertions',
            width: 100,
            render: (_, record) => (
                <Space>
                    {record.assertions?.map((assertion, index) => (
                        <Tag key={index}>
                            <span className='fw-bolder' >
                                {assertion}
                            </span>
                        </Tag>
                    ))}
                </Space>
            ),
        },
    ];

    const handleSearch = () => {
        setLoading(true);

        setTimeout(() => {
            const filteredClients = programmes.filter((ident) => {
                const {
                    question,
                    assertion,
                } = paramsRecherche;

                const lowercaseQuestion = question?.toLowerCase();
                const lowercaseAssertion = assertion?.toLowerCase();


                const matchQuestion =
                    !question ||
                    (ident.libelle.some(lib =>
                            lib?.toLowerCase()
                            ?.includes(lowercaseQuestion)) ||
                        ident.reference
                            ?.toLowerCase()
                            ?.includes(lowercaseQuestion) );

                const matchAssertion =
                    !assertion ||
                    (ident.assertions ?? []).some(ass =>
                        ass?.toLowerCase()
                            ?.includes(lowercaseAssertion)
                    );


                return (
                    matchQuestion &&
                    matchAssertion
                );
            });

            setFilteredItems(filteredClients)

            setLoading(false);
        }, 1000);
    };

    const handleReset = () => {
        setLoading(true);
        setParamsRecherche({
            question: "",
            cycleId: null,
            rubriqueId: null,
            assertion: "",
        });
        setFilteredItems(programmes);
        setLoading(false);
    };

    return (
        <ModalAlert
            closeModal={handleVisibility}
            show={visible}
            title={<ModalTitle> Sélectionner les questions de travail (sources) </ModalTitle>}
            footer={[]}
            modalStyles={{ top: 10 }}
            width={1300}
        >
            <FormContainer>
                <SearchRow
                    handleSearch={handleSearch}
                    handleReset={handleReset}
                    loading={loading}
                >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 16 }}
                        lg={{ span: 16 }}
                        xl={{ span: 16 }}
                    >
                        <FormLabel label="Question" />
                        <Input
                            style={{ width: "100%" }}
                            size="large"
                            placeholder="Rechercher par la référence, libellé"
                            value={paramsRecherche.question || null}
                            onChange={(e) => {
                                setParamsRecherche({
                                    ...paramsRecherche,
                                    question: e.target.value,
                                })
                            }}
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}
                        xl={{ span: 8 }}
                    >
                        <FormLabel label="Assertions" />
                        <Select
                            showSearch
                            placeholder="Rechercher par l'assertion..."
                            optionFilterProp="label"
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={assertionsData.map((assertion) => ({
                                value: assertion.libelle,
                                label: assertion.libelle,
                            }))}
                            value={paramsRecherche.assertion || null}
                            onSelect={(value) =>
                                setParamsRecherche({
                                    ...paramsRecherche,
                                    assertion: value,
                                })
                            }
                            size="large"
                            style={{ width: "100%" }}
                        />
                    </Col>
                </SearchRow>
            </FormContainer>
            <Row gutter={[16, 16]}>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 16 }}
                    lg={{ span: 14 }}
                    xl={{ span: 14 }}
                >
                    <FormContainer>
                        <div className="table-responsive">
                            <Table
                                rowClassName={() =>
                                    `h-3px p-0 m-0 lh-0.5`
                                }
                                columns={columsProgrammes}
                                dataSource={filteredItems ?? []}
                                pagination={false}
                                scroll={{y: 400}}
                                loading={chargement || loading}
                                size={"small"}
                            />
                        </div>
                    </FormContainer>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 10 }}
                    xl={{ span: 10 }}
                >
                    <FormContainer>
                        <div className="table-responsive">
                            <Table
                                rowClassName={() =>
                                    `h-3px p-0 m-0 lh-0.5`
                                }
                                columns={columnsSelectQuestions}
                                dataSource={programmesTravail ?? []}
                                pagination={false}
                                scroll={{y: 400}}
                                loading={chargement || loading}
                                size={"small"}
                            />
                        </div>
                    </FormContainer>
                </Col>
            </Row>
            <div className="d-flex justify-content-center align-items-center mt-3">
                <div className="w-350px">
                    <Button
                        type="primary"
                        block
                        size="large"
                        // icon={<FontAwesomeIcon icon={fa} /> }
                        loading={loading}
                        disabled={loading}
                        onClick={handleValidate}
                    >
                        Valider
                    </Button>
                </div>
            </div>
        </ModalAlert>
    );
};

const TravauxAudit = ({
          mission, 
          client, 
          tabMaitresse, 
          setConclusionGenerale,
          conclusionGenerale,
          procedure_id,
          menuLibelle, 
          code, 
          isProvisoire, 
          travauxData, 
          isAddMemoire, 
          setIsAddMemoire, 
          setPrintData,
          chargement,
          setIsTaskSave,
          isTaskSave,
    }) => {
    const [loading, setLoading] = useState(false);
    const [datas, setDatas] = useState([]);
    const [programmeData, setProgrameData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedModalId, setSelectedModalId] = useState(null);
    const [risqueIncidences, setRisqueIncidences] = useState([]);
    const [assertions, setAssertions] = useState([]);
    const [assertData, setAssertData] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(() => {
       if(travauxData){
           const updatedList = travauxData.map((item) => ({
               id: item.id,
               programme_id: item.id,
               ref: item.ref,
               libelle: item.libelle,
               assertions: item.assertions,
               commentaire: item.commentaire,
               conclusion: item.conclusion
           }))

           setDatas(updatedList)
       }
    }, [travauxData])

    useEffect(() => {
        setLoading(true);
        getDetailsRisqueEvaluationAll(mission.id).then((res) => {
            if (res.result.length > 0) {
                const arrayData = [];
                const uniqueAssertions = new Set();

                res.result.forEach((event) => {
                    event.Lea_RisqueIdentificationDetails?.forEach((detail) => {
                        const { Lea_RisqueIdentificationAssertions, Lea_RisqueEvaluations } = detail;
                        const assertions = Lea_RisqueIdentificationAssertions[0]?.assertions || [];
                        const probabiliteValue = Lea_RisqueEvaluations[0]?.probabiliteValue || '';
                        const impactValue = Lea_RisqueEvaluations[0]?.impactValue || '';
                        const score = Lea_RisqueEvaluations[0]?.score || 0;

                        const newSelectArray = {
                            id: detail.id,
                            incidence: detail.incidence,
                            poste: assertions[0]?.poste,
                            assertions: assertions,
                            probabiliteValue: probabiliteValue,
                            probabilite_id: Lea_RisqueEvaluations[0]?.probabilite_id || '',
                            impactValue: impactValue,
                            impact_id: Lea_RisqueEvaluations[0]?.impact_id || '',
                            score: score,
                            niveau_id: Lea_RisqueEvaluations[0]?.niveau_id || '',
                        };

                        arrayData.push(newSelectArray);

                        assertions.forEach((item) => uniqueAssertions.add(item));
                    });
                });

                const asserts = Array.from(uniqueAssertions);
                if (!areArraysEqual(asserts, assertData)) {
                    setAssertData(asserts);
                }

                const updateItem = arrayData.filter((data) => data.impactValue * data.probabiliteValue > 5);

                setRisqueIncidences(updateItem);
            }
            setLoading(false);
        });
    }, [assertData, mission]);

    useEffect(() => {
        const fetchAssertion = async () => {
            try {
                const res = await getListeAssertionActive();
                const asserts = res.result;
                setAssertions(asserts);
            } catch (error) {
                console.error('Erreur lors de la récupération des assertions', error);
            }
        };

        fetchAssertion();
    }, []);

    useEffect(() => {
        setPrintData(datas)
    }, [datas, setPrintData])

    const handleOpenModal = (modal_id, item) => {
        setProgrameData(item)
        setSelectedModalId(modal_id);
        setIsModalOpen(prevState => !prevState);
    };

    const handleDeleteElement = (index) => {
        setLoading(true);
        setTimeout(() => {
            const updatedList = datas.filter((_, i) => i !== index);
            setDatas(updatedList);
            // setIsTaskSave();
            setLoading(false);
        }, 1000)
    };

    const handleSubmit = async () => {
        setLoading(true);

        const data = {
            exercice_id: mission.id,
            client_id: mission.client_id,
            procedure_id,
            travauxData: datas,
            isProvisoire,
            // sommaire_id,
            codeSommaire: code,
        };

        const {status, message} = await ajouterTravauxAudit(data)
        if (status === 'success') {
            setLoading(false)
            await swal(`Opération effectuée avec succès`, '', 'success')
            // setIsTaskSave(false);
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    }

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>REF</TableTitle>,
            dataIndex: 'reference',
            width: 40,
            render: (_, record) => (
                <Tag>
                    <span className='fw-bolder' style={{fontSize: '13px'}}>
                        {record.ref}
                    </span>
                </Tag>
            ),
        },
        {
            title: () => <TableTitle>NATURE DES TRAVAUX A FAIRE</TableTitle>,
            dataIndex: 'libelle',
            width: 350,
            render: (_, record) => (
                <div className="">
                    <Input.TextArea
                        style={{fontSize: "14px"}}
                        size="large"
                        bordered={false}
                        placeholder="Saisisser le libellé..."
                        value={record.libelle}
                        maxLength={2000}
                        // showCount
                        autoSize={{
                            minRows: 1,
                            maxRows: 6,
                        }}
                    />
                    {/*<span className='' style={{fontSize: '14px'}}>*/}
                    {/*    {record.libelle}*/}
                    {/*</span>*/}
                </div>
            ),
        },
        {
            title: () => <TableTitle>ASSERTIONS</TableTitle>,
            dataIndex: 'assertions',
            width: 170,
            render: (_, record) => (
                // <Select
                //     mode='tags'
                //     style={{width: '100%', fontSize: "12px"}}
                //     placeholder='choisir les assertions'
                //     value={record.assertions}
                //     options={assertions.map((item) => ({
                //         label: item.libelle,
                //         value: item.libelle,
                //     }))}
                //     onChange={(value) => {
                //         const updated = [...datas];
                //         updated[index].assertions = value;
                //         setDatas(updated);
                //     }}
                // />
                <Space>
                    {record.assertions?.map((assertion, index) => (
                        <Tag key={index}>
                            <span className='fw-bolder' >
                                {assertion}
                            </span>
                        </Tag>
                    ))}
                </Space>
            ),
        },
        {
            title: () => <TableTitle>Commentaire</TableTitle>,
            dataIndex: 'action',
            width: 300,
            render: (_, record, index) => (
                <div className="">
                    <span className='' style={{fontSize: '14px'}}>
                        {record.commentaire}
                    </span>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            width: 100,
            render: (_, record, index) => (
                <Space>
                    <Tooltip title='Procéder au travaux '>
                        <Button
                            onClick={() => handleOpenModal(2, record)}
                            shape="circle"
                            style={{backgroundColor: appTheme.colors.primary, color: "white"}}
                            icon={<FontAwesomeIcon icon={faEdit} />}
                        />
                    </Tooltip>
                    <Tooltip title='Supprimer une queston'>
                        <Button
                            onClick={() => handleDeleteElement(index)}
                            title="Supprimer ?"
                            type="primary"
                            icon={<DeleteOutlined />}
                            danger
                        />
                    </Tooltip>

                </Space>
            ),
        },
    ];

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

  return (
      <>
          <div>
              <div className="d-flex justify-content-end align-items-end mb-10">
                  <Tooltip title="Enregistrer">
                      <Button
                          disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                          size={"large"}
                          // loading={loading}
                          style={{
                              backgroundColor: appTheme.colors.secondary,
                              color: "white"
                          }}
                          icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                          onClick={handleSubmit}
                      >
                          {!(loading || chargement) &&
                              <span className='indicator-label text-white'>Enregistrer</span>}
                          {(loading || chargement) && (
                              <span className='indicator-progress fs-9 text-white'
                                    style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                          )}
                      </Button>
                  </Tooltip>
              </div>
              <div className="table-responsive mx-2">

                  <div className="d-flex justify-content-end align-center my-2 me-5">
                      <div className="d-flex flex-wrap align-center">
                          <Space>
                              {(mission.isCloture !== 1 || mission.isClotureProvisoire !==1) && <Tooltip color={appTheme.colors.primary} title="Ajouter une nouvelle question">
                                  <Button
                                      size={"middle"}
                                      style={{color: "white", backgroundColor: appTheme.colors.primary}}
                                      onClick={() => handleOpenModal(1)}
                                      icon={<FontAwesomeIcon icon={faCloudUpload}/>}
                                  >
                                      Charger
                                  </Button>
                              </Tooltip>}
                          </Space>
                      </div>
                  </div>

                  <Table
                      onRow={(record, index) => {
                          return {
                              onClick: () => {
                                  handleOpenModal(2, record)
                                  setSelectedRowKeys([index]);
                              },
                          };
                      }}
                      rowClassName={(record, index) =>
                          selectedRowKeys.includes(index) ? 'cursor-pointer h-3px p-0 m-0 lh-0.5 highlighted-row' : 'cursor-pointer h-3px p-0 m-0 lh-0.5'
                      }
                      columns={columns}
                      rowKey={(record, index) => index}
                      dataSource={datas ?? []}
                      pagination={false}
                      // scroll={{ y: 1000}}
                      loading={chargement || loading}
                      rowSelection={{
                          type: 'radio',
                          ...rowSelection,
                      }}
                  />
              </div>
              <br/>
              <br/>
              <br/>
          </div>
          {isModalOpen && selectedModalId === 1 && (
              <ModalProgrammeStandard
                  handleVisibility={handleOpenModal}
                  visible={isModalOpen}
                  selectData={datas}
                  setSelectData={setDatas}
                  procedure_id={procedure_id}
                  menuLibelle={menuLibelle}
                  code={code}
                  risqueIncidences={risqueIncidences}
                  assertData={assertData}
                  assertions={assertions}
                  codeSommaire={code}
                  chargement={chargement || loading}
              />
          )}
          {isModalOpen && selectedModalId === 2 && (
              <TravauxModal
                  handleVisibility={handleOpenModal}
                  visible={isModalOpen}
                  programme={programmeData}
                  selectData={datas}
                  setSelectData={setDatas}
                  mission={mission}
                  client={client}
                  tabMaitresse={tabMaitresse}
                  conclusionGenerale={conclusionGenerale}
                  setConclusionGenerale={setConclusionGenerale}
                  procedure_id={procedure_id}
                  menuLibelle={menuLibelle}
                  isProvisoire={isProvisoire}
                  isAddMemoire={isAddMemoire}
                  setIsAddMemoire={setIsAddMemoire}
                  codeSommaire={code}
                  assertions={assertions}
                  chargement={chargement || loading}
                  setIsTaskSave={setIsTaskSave}
                  isTaskSave={isTaskSave}
              />
          )}
      </>
  )
}

export {TravauxAudit}