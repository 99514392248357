import React, {useEffect, useState} from "react";
import { Identification } from "../../Identification";
import {FicheR4} from "./proceduresModal/notesAnnexe/FicheR4";
import {NoteAnnexeObservationGenerale} from "./proceduresModal/notesAnnexe/NoteAnnexeObservationGenerale";
import {ajouterProcedureNoteAnnexe, getProcedureNoteAnnexe} from "../../../../../data/procedureAudit";
import swal from "sweetalert";
import {succesMessage} from "../../../../../helpers/AssetsHelpers";
import {getListeIdentificationSommaire} from "../../../../../data/rateau";
import {faIdCard, faSave, faTasks} from "@fortawesome/free-solid-svg-icons";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import {Button, Tooltip} from "antd";
import {appTheme} from "../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PrintFicheR4 from "./proceduresModal/notesAnnexe/PrintFicheR4";

const ControleNoteAnnexe =  ({
         mission,
         client,
         code,
         // modalId,
         prev
     }) => {
    const [activeTab, setActiveTab] = useState(1);
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [codeSommaire] = useState(code || 'EE3.15');
    const [date, setDate] = useState('');
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);
    const [identifications, setIdentifications] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectYear, setSelectYear] = useState(mission.annee || "");
    const [missionSelected, setMissionSelected] = useState(mission || null);
    const [isProvisoire, setIsProvisoire] = useState('1');
    const [isTaskSave, setIsTaskSave] = useState([]);

    const [noteData, setNoteData] = useState([]);
    const [excelPrintData, setExcelPrintData] = useState([]);
    const [observation, setObservation] = useState('')
    const [conclusion, setConclusion] = useState('')
    const [isAddMemoire, setIsAddMemoire] = useState(false)

    useEffect(()=> {
        getProcedureNoteAnnexe(missionSelected.id)
            .then((res) => {
                if(res.result){
                    const noteFetch = res.result.codeSommaire === codeSommaire
                    if(noteFetch){
                        setObservation(res.result.observation)
                        setConclusion(res.result.conclusion)
                        setNoteData(res.result?.Lea_ProcedureNoteAnnexeRecaps);
                    }
                    else{
                        setObservation('')
                        setNoteData([]);
                    }
                }
            })
    }, [codeSommaire, missionSelected])

    useEffect(()=> {
        if(activeTab === 0){
            getListeIdentificationSommaire(missionSelected.id, codeSommaire)
                .then((res) => {
                    setIdentifications(res.result)
                })
        }
    }, [activeTab, codeSommaire, missionSelected])

    useEffect(() => {
        if((noteData ?? []).length > 0){
            const data = noteData.map((item, index )=> ({
                index: index + 1,
                note: item.note,
                intitule: item.libelle,
                reponse: item.reponse === '1' ? 'A' : 'N/A',
                commentaire: item.commentaire,
            }))
            setExcelPrintData(data)
        }
    }, [noteData])

    const handleSubmit = async (e) => {
        e.preventDefault()

        const data = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            noteData,
            conclusion,
            isAddMemoire,
            isProvisoire,
            observation,
            codeSommaire: code,
            exercice_id: missionSelected.id,
            client_id: missionSelected.client_id
        }

        const {status, message} = await ajouterProcedureNoteAnnexe(data)
        if (status === 'success') {
            setLoading(false)
            await swal(succesMessage, '', 'success')
            setIsTaskSave([]);
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    }

    const formSteps = [
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: (
                <Identification
                    identifications={identifications}
                    mission={missionSelected}
                />
            ),
        },
        {
            code: "FR4",
            title: "Fiche R4",
            icon: faTasks,
            content: (
                <>
                    <FicheR4
                        client={client}
                        code={codeSommaire}
                        isProvisoire={isProvisoire}
                        setIsAddMemoire={setIsAddMemoire}
                        isAddMemoire={isAddMemoire}
                        setConclusion={setConclusion}
                        conclusion={conclusion}
                        selectItems={noteData}
                        setSelectItems={setNoteData}
                        chargement={loading}
                        mission={missionSelected}
                        setIsTaskSave={setIsTaskSave}
                    />
                </>
            ),
        },
        {
            code: "OG",
            title: `Observation générale - ${selectYear}`,
            description: `Observation générale - ${selectYear}`,
            icon: faTasks,
            content: (
                <NoteAnnexeObservationGenerale
                    observation={observation}
                    setObservation={setObservation}
                    setIsTaskSave={setIsTaskSave}
                />
            ),
        },
    ];

    return (
        <>
            <div className="mx-5">
                <PageToolbarHeader
                    prev={prev} codeSommaire={codeSommaire}
                    pageTitle={"EE3.15 - Procédure d'audit complémentaire: Contrôle des notes annexes"}
                    mission={missionSelected}
                    setMission={setMissionSelected}
                    isProvisoire={isProvisoire}
                    setIsProvisoire={setIsProvisoire}
                    selectYear={selectYear}
                    setSelectYear={setSelectYear}
                    loading={loading}
                    setLoading={setLoading}
                    client={client}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    setDate={setDate}
                    setTache_id={setTache_id}
                    tache_id={tache_id}
                    setIntervention_id={setIntervention_id}
                    intervention_id={intervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    isExcelPrint={true}
                    isLandingScape={true}
                    printTitle={(`EE3.15 - Procédure d'audit complémentaire: Contrôle des notes annexes ${selectYear}`)}
                    excelData={excelPrintData}
                    printContent={(
                        <PrintFicheR4
                            datas={noteData}
                            title={""}
                            conclusion={conclusion}
                            observation={observation}
                        />
                    )}
                />
                <StepHeader>
                    <ModalNavBarMenu
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        steps={formSteps}
                        setLoading={setLoading}
                        loading={loading}
                    />
                </StepHeader>
                <StepBody>
                    <>
                        <div className="d-flex justify-content-end align-items-end mb-10">
                            <Tooltip title="Enregistrer">
                                <Button
                                    disabled={(missionSelected.isCloture === 1 || missionSelected.isClotureProvisoire === 1)}
                                    size={"large"}
                                    // loading={loading}
                                    style={{
                                        backgroundColor: appTheme.colors.secondary,
                                        color: "white"
                                    }}
                                    icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                                    onClick={handleSubmit}
                                >
                                    {!loading &&
                                        <span className='indicator-label text-white'>Enregistrer</span>}
                                    {loading && (
                                        <span className='indicator-progress fs-9 text-white'
                                              style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                    )}
                                </Button>
                            </Tooltip>
                        </div>
                        {formSteps[activeTab].content}
                    </>
                </StepBody>
            </div>
        </>
    );
}

export {ControleNoteAnnexe}